import { useSelector } from 'react-redux';
import { IState } from '../reducers/reducer';
import { Translation } from '@visikon/core-models/languageTypes';
import { ContentContainerType, Department, isToggled, MitforlobContent, VideoPlaylist } from '../reducers/resourcesReducer';
import { HasTranslations } from '@visikon/core-models/typeUtils';
import { contentConfig, validContentKeys } from './ContentConfig';
import { IUserDataState, IVideoProgress } from '../reducers/userDataReducer';
import { MediaUtils } from './utils';
import { useParams } from 'react-router-dom';
import { backbone, useBackbone } from '@visikon/backbone/src';
import { ProgramsStorage } from '../local-storage/programs-storage';
import { UserInfo } from '@visikon/backbone/src/models';
import { FetchProgramPayload } from '../actions/resourcesActions';

import { IState as IAuthState } from './../reducers/authReducer';
import { LanguageCode } from '@visikon/core-models/content';
import { Country } from '@visikon/core-models/i18n/languages';
import { i18n } from '../internationalization/i18n';
import { Content } from '@visikon/core-models';

export function useLanguage(): LanguageCode {
  const country = useBackbone((state) => state.country);
  return country.languageCode as LanguageCode;
}

export function useCountry(): Country {
  return useBackbone((state) => state.country);
}

export function useIsOffLine() {
  return useBackbone((state) => state.isOffLine);
}

export function useUserData(): IUserDataState {
  return useSelector<IState, IUserDataState>((state) => state.userData);
}

export function useAuth() {
  return useSelector<IState, IAuthState>((state: IState) => state.auth);
}

export function useSelectPreviewActiveProgram() {
  return useSelector<IState, { showPreview?: boolean; activeProgram?: string }>((state) => ({
    showPreview: state.resources.showPreview,
    activeProgram: state.resources.activeProgram,
  }));
}

export function usePreview() {
  const { showPreview, activeProgram } = useSelectPreviewActiveProgram();
  return resolvePreview(activeProgram, showPreview);
}
export async function usePreviewForActiveProgram(activeProgram, showPreview) {
  return resolvePreview(activeProgram, showPreview);
}

export async function getProgramPath({ id, showPreview }: FetchProgramPayload) {
  const preview = await resolvePreview(id, showPreview);
  return preview.path;
}

export type PreviewInfo = {
  path?: string;
  isReviewer?: boolean;
  isPreview?: boolean;
  hasPreview: boolean;
  hasLive: boolean;
};

interface ContentOptions {
  isReviewer: boolean;
  hasPreview: boolean;
  hasLive: boolean;
  live_path?: string;
  preview_path?: string;
}

export async function GetAvailableContentOptions(activeProgramId?: string): Promise<ContentOptions> {
  const embedInfo = useBackbone.getState().embedInfo;
  if (embedInfo) {
    console.log('using backbone store to get content options');
    return {
      isReviewer: useBackbone.getState().user?.reviewer === true,
      hasPreview: embedInfo.preview_path !== undefined,
      hasLive: embedInfo.live_path !== undefined,
      preview_path: embedInfo.preview_path,
      live_path: embedInfo.live_path,
    };
  }

  const isReviewer = reviewerCheck(backbone.store.getState().user);
  const programs = await ProgramsStorage.get();
  const program = programs?.availablePrograms?.programs.find((p) => p._id === activeProgramId);
  const hasPreview = !!program?.published?.preview;
  const hasLive = !!program?.published?.live;

  return {
    isReviewer,
    hasPreview,
    hasLive,
    live_path: program?.published?.live?.path,
    preview_path: program?.published?.preview?.path,
  };
}

export function SelectPublishedVersion(
  { isReviewer, hasPreview, hasLive, preview_path, live_path }: ContentOptions,
  showPreview?: boolean,
): PreviewInfo {
  const mustShowPreview = isReviewer && hasPreview && showPreview;
  const canFallbackToPreview = isReviewer && hasPreview && !hasLive;

  const isPreview = mustShowPreview || canFallbackToPreview;
  const path = isPreview ? preview_path : live_path;

  return { path, isReviewer, isPreview, hasPreview, hasLive };
}

export async function resolvePreview(activeProgramId?: string, showPreview?: boolean): Promise<PreviewInfo> {
  const options = await GetAvailableContentOptions(activeProgramId);

  return SelectPublishedVersion(options, showPreview);
}

function reviewerCheck(user?: UserInfo): boolean {
  return user?.type !== 'EndUser' && user?.reviewer === true;
}

export function useSeenPrivacyPolicy() {
  return useSelector<IState, boolean>((state) => Boolean(state.userData.seenPrivacyPolicy) || isToggled('onboarding')(state));
}

export function useIsPrivacyPolicyShowing() {
  return useSelector<IState, boolean>((state) => Boolean(state.resources.isShowingPrivacyPolicy));
}

export function usePrivacyPolicyAcceptedEvent() {
  return useSelector<IState, Function | undefined>((state) => state.resources.privacyPolicyAcceptedEvent);
}

export function useIsUserDataNotLoaded() {
  return useSelector<IState, boolean>((state) => state.userData.lastUpdate === undefined);
}

export function useWholeContent() {
  return useSelector<IState, MitforlobContent>((state) => state.resources.content || ({} as MitforlobContent));
}

export function useContent<T extends HasTranslations>(fieldKey: string): Translation<T> {
  return useSelector<IState, Translation<T>>((state) => state.resources.content?.[fieldKey]);
}
export function useProgramActions(): Content.Action[] {
  return useSelector<IState, Content.Action[]>((state) => state.resources?.programInfo?.actions || []);
}

export function useContentKeys() {
  return useSelector(getValidContentKeys);
}

export const getValidContentKeys = (state: IState) => {
  const contentKeys = state.resources.content ? Object.keys(state.resources.content) : [];
  return validContentKeys.filter((key) => contentKeys.includes(key));
};

export function useHasOneMenuItem() {
  return useSelector(getValidContentKeys).length === 1;
}

export function useIsFirstMenuItem() {
  const firstContentConfig = useFirstValidContentConfig();
  if (!firstContentConfig) {
    return null;
  }

  return location.pathname.endsWith(firstContentConfig.path);
}

export function useFirstValidContentKey() {
  const contentKeys = useSelector(getValidContentKeys);
  return contentKeys[0];
}

export function useTranslatedPage(): string | undefined {
  const currentConfig = useCurrentContentConfig();
  if (!currentConfig) {
    return undefined;
  }
  return i18n(currentConfig.translationKeys.page);
}

export function useCurrentContentConfig() {
  const contentKeys = useSelector(getValidContentKeys);
  const key = contentKeys.find((key) => location.pathname.includes(contentConfig[key].path));
  return key ? contentConfig[key] : undefined;
}
export function useFirstValidContentConfig() {
  return contentConfig[useFirstValidContentKey()];
}

export function useVideoContentLists(): Array<Translation<VideoPlaylist>> {
  const validContentKeys = useContentKeys();
  const contentKeys = useSelector<IState, MitforlobContent>((state) => state.resources.content || ({} as MitforlobContent));

  return Object.keys(contentKeys)
    .filter((key) => validContentKeys.includes(key))
    .map((key) => contentKeys[key])
    .filter((item) => item.typeDescriptor === 'videoList');
}

export function useContainerContentLists(): Array<Translation<ContentContainerType>> {
  const validContentKeys = useContentKeys();
  const contentKeys = useSelector<IState, MitforlobContent>((state) => state.resources.content || ({} as MitforlobContent));

  return Object.keys(contentKeys)
    .filter((key) => validContentKeys.includes(key))
    .map((key) => contentKeys[key])
    .filter((item) => item.typeDescriptor === 'contentContainer');
}

export function useId() {
  const { id } = useParams<{ id: string }>();
  return id;
}

export function useVideo() {
  const videoContentLists = useVideoContentLists();
  const id = useId();

  return MediaUtils.searchForVideoInfo(id, ...(videoContentLists as Array<Translation<VideoPlaylist>>));
}

export function useVideoProgressList() {
  return useSelector<IState, IVideoProgress[]>((state) => state.userData.videoProgress);
}

export type ProgramInfo = {
  name?: string;
  subName?: string;
  department?: any;
  genericHeader?: boolean;
  programType?: string;
  enableDirectAccess?: boolean;
  footer?: string;
};

export async function useProgramInformationFromContent(content): Promise<ProgramInfo> {
  const programs = await ProgramsStorage.get();

  return {
    name: content?.name || '',
    subName: content?.subName || '',
    department: content?.department || ({} as Department),
    genericHeader: content?.genericHeader,
    footer: content?.footer,
    programType: content?.programType || '',
    enableDirectAccess: programs?.availablePrograms?.programs.find((p) => p._id === content?._id)?.enableDirectAccess,
  };
}
